import Head from 'next/head';
import { renderMetaTags } from 'react-datocms';
import type { TitleMetaLinkTag } from 'react-datocms';

export interface MetaTag {
  tag: string;
  /** the inner content of the meta tag */
  content: string | null;
  /** the HTML attributes to attach to the meta tag */
  attributes: Record<string, string> | null;
}

export interface DatoMetaTagProps {
  metaTags: readonly MetaTag[];
  canonicalUrl?: string;
  noIndex?: boolean;
}

const DatoMetaTags: React.FC<DatoMetaTagProps> = ({ metaTags, canonicalUrl, noIndex }) => {
  return (
    <Head>
      {renderMetaTags(metaTags as TitleMetaLinkTag[])}
      {!!canonicalUrl && (
        <>
          <link rel="canonical" href={canonicalUrl} key="canonical" />
          <meta property="og:url" key="meta-og:url" content={canonicalUrl} />
        </>
      )}

      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default DatoMetaTags;
